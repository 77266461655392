@import "../../styles/mixins";

.carrousel {
    width: 100%;
    height: 415px;
    position: relative;
    margin-bottom: 40px;

    @include mobile {
        height: 255px;
        margin-bottom: 20px;
    }

    &__picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 25px;

        @include mobile {
            border-radius: 10px;
        }


    }

    &__counter {
        position: absolute;
        bottom: 20px;
        text-align: center;
        width: 100%;
        font-size: rem(18);
        color: #fff;
        text-shadow: -1px 1px 3px rgba(0,0,0,0.2);
    }

    &__btn {
        width: 96px;
        // height: 120px;
        position: absolute;
        top: 147px;
        padding: 0 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        filter: drop-shadow(-1px 1px 3px rgba(0,0,0,0.2));


        @include tablet {
            width: 80px;
            top: 174px;
        }
        
        @include mobile {
            width: 60px;
            top: 110px;
        }

        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
    }

    &__btn--svg path {
        fill: #fff;
    }

}