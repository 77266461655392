@import "mixins";
@import "reset";
@import "fonts";
//@import "main";

#root {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
}

.main {
    max-width: 1440px;
    padding: 0 100px;
    width: 100%;
    height: max-content;
    flex-grow: 4;

    @include tablet {
        padding: 0 50px 20px 50px;
    }

    @include mobile {
        padding: 0 20px 20px 20px;
    }
}
