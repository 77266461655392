@import "../../styles/mixins";

.wrap-loc {
    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
    }

}
.loc {

    @include mobile {
        width: 100%; 
    }


    &__title {
        font-size: rem(36);
        font-weight: 500;
        color: $primary;
        margin-bottom: 12px;
        line-height: 140%;

        @include mobile {
            font-size: rem(18);
        }
    }
    &__location {
        font-size: rem(18);

        @include mobile {
            font-size: rem(14);
        }
    }
    &__tags {
        margin-top: 25px;
        display: flex;
        gap: 10px;

        @include mobile {
            margin-top: 12px;
        }

        &--tag {
            font-size: rem(14);
            font-weight: 700;
            color: #fff;
            background-color: $primary;
            padding: 6px;
            border-radius: 10px;
            min-width: 115px;
            text-align: center;

            @include mobile {
                font-size: rem(10);
                min-width: 85px;
                border-radius: 5px;
            }
        }
    }
    &__rating {
        display: flex;
        height: 24px;
        gap: 12px;
        justify-content: flex-end;
        margin-top: 20px;

        @include mobile {
            height: 13px;
            margin-top: 0;    
            gap: 6px;
        }


        svg {
            height: 24px;

            @include mobile {
                height: 13px;
            }
        }

        &__star path{
            fill: #E3E3E3;
        }

        & .active path{
            fill: $primary;
        }
    }

}

.infos-host {
    display: flex;
    flex-direction: column;

    @include mobile {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
    }

    .host {
        display: flex;
        align-items: center;
        gap: 9px;

        &__name {
            font-size: rem(18);
            font-weight: 500;
            color: $primary;
            width: 150px;
            text-align: right;
            line-height: 120%;

            @include mobile {
                font-size: rem(12);
                width: 100px;
            }

        }
        &__picture {
            width: 64px;
            height: 64px;
            border-radius: 100%;

            @include mobile {
                width: 32px;
                height: 32px;
            }

        }
    }
}


.lodging {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px 0 50px 0;

    @include mobile {
        margin: 20px 0 0 0;
    }

}