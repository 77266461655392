/* montserrat-500 - latin */
@font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/montserrat-v26-latin-500.woff2') format('woff2'); 
  }
  
  /* montserrat-500italic - latin */
  @font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/montserrat-v26-latin-500italic.woff2') format('woff2'); 
  }
  
  /* montserrat-700 - latin */
  @font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/montserrat-v26-latin-700.woff2') format('woff2'); 
  }
  
  /* montserrat-700italic - latin */
  @font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/montserrat-v26-latin-700italic.woff2') format('woff2'); 
  }

  body {
    font-family: "Montserrat";
    font-weight: 500;
    color: #000;
    font-size: 16px;
  }
  